<template>
    <v-app style="background-color: #191B20;">
        <v-container class="my-3 mx-xs-0">
            <v-row>
                <v-img class="my-4" lazy-src="https://picsum.photos/id/11/10/6" max-height="64" max-width="100"
                    src="@/assets/images/logo1.svg"></v-img>
            </v-row>
            <v-row class="mt-12">
                <p class="text-h4 yellow--text text--darken-3">ลืมรหัสผ่าน</p>
            </v-row>
            <v-row>
                <p class="text-subtitle-1 white--text">กรุณากรอกอีเมลล์เพื่อรับรหัสผ่านใหม่</p>
            </v-row>
            <v-row>
                <v-form ref="form" v-model="isValid" class="w-400px" @submit.prevent="submit">
                    <v-text-field dark color="yellow darken-3" v-model="email" label="กรุณากรอกอีเมลล์" outlined dense
                        hint="ระบบจะทำการส่ง Activation Code ไปยังอีเมลล์ของคุณเพื่อรีเซ็ตรหัสผ่านใหม่" persistent-hint
                        :rules="emailRules"></v-text-field>
                    <v-row>
                        <v-col cols="12">
                            <v-btn color="yellow darken-3" @click="submit" class="w-400px mt-3">ต่อไป</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-row>
        </v-container>
        <alert-error :text="errorMessage" ref="alertError" />
        <alert-success :text="successMessage" ref="alertSuccess" />
    </v-app>
</template>

<script>
import AlertError from '../components/AlertError.vue';
import AlertSuccess from '../components/AlertSuccess.vue';
import axios from 'axios';

export default {
    data: () => ({
        email: '',
        isValid: false,
        errorMessage: '',
        successMessage: '',
        emailRules: [
            v => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง';
            },
            v => !!v || 'โปรดกรอกอีเมล'
        ]
    }),
    methods: {
        async submit(){
            this.$refs.form.validate();
            this.errorMessage = '';
            if(this.isValid){
                const loader = this.$loading.show();
                try{
                    await axios.post(process.env.VUE_APP_BASE_URL + '/forgot-password/send/' + this.email);
                    this.successMessage = 'ส่งอีเมลสำหรับรีเซ็ตรหัสผ่านแล้ว โปรดตรวจสอบอีเมลของคุณ';
                    this.$refs.alertSuccess.show();
                }catch(error){
                    this.errorMessage = error.response?.data[0]?.msg || error.response?.data?.fail || error.message;
                    this.$refs.alertError.show();
                }finally{
                    loader.hide();
                }
            }
            
        }
    },
    components: {
        AlertError,
        AlertSuccess
    }
};
</script>

<style scoped>
    .w-400px{
        width: 400px;
    }
</style>